import React, { useState, useEffect, useRef, useCallback } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Typography, IconButton, Tooltip } from '@mui/material';
import PublishOutlinedIcon from '@mui/icons-material/PublishOutlined';

import DocumentCard from './DocumentCard';
import Filters from './Filters';
import PatientDocumentIcon from '../../../assets/icons/PatientInfoCards/registration-icon';
import { PATIENT_DOCUMENT_FILTERS } from '@worklist-2/worklist/src/DocumentViewerV3/consts/consts';

import { usePatientStore } from '@rs-ui/views/PatientInformationView/store/patientStore';
import NoDocuments from './NoDocuments';
import { usePatientInfoCardStore } from '@rs-ui/components/PatientInfoCards/store/patientInfoCardStore';
import { useDocumentViewerContext } from '@worklist-2/worklist/src/DocumentViewerV3/contexts/DocumentViewerContextProvider';

import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const FILTER_LABEL = {
	[PATIENT_DOCUMENT_FILTERS.ALL]: 'All Documents',
	[PATIENT_DOCUMENT_FILTERS.UPLOADED_DOCUMENT]: 'Uploaded Documents',
	[PATIENT_DOCUMENT_FILTERS.REGISTRATION_FORM]: 'Registration Form',
};

const PatientDocument = ({
	rootHeight,
	isSelected,
	isHover,
	setCollapsed,
	isExpandMain,
	handleSelectDocument,
	handleUploadDocument,
}) => {
	const { documents: patientDocuments } = usePatientStore();
	const { selectedDocFilter, setSelectedDocFilter } = usePatientInfoCardStore();
	const { viewports } = useDocumentViewerContext();
	const galaxyDvGlobalNotes = useBooleanFlagValue('galaxy-dv-global-notes');
	const galaxyDvPatientChartRefactor = useBooleanFlagValue('galaxy-dv-patient-chart-refactor');

	const [patientDocs, setPatientDocs] = useState([]);

	const [expanded, setExpanded] = useState(true);
	const [selectedPatientDocument, setSelectedPatientDocument] = useState();

	const refInput = useRef();

	useEffect(() => {
		if (Array.isArray(patientDocuments)) {
			if (selectedDocFilter === PATIENT_DOCUMENT_FILTERS.ALL) {
				setPatientDocs(patientDocuments);
			} else {
				setPatientDocs(patientDocuments.filter(x => x.documentType === selectedDocFilter));
			}
		} else {
			setPatientDocs([]); // Set default value or handle error
		}
	}, [patientDocuments, selectedDocFilter]);

	useEffect(() => {
		setExpanded(isExpandMain);
	}, [isExpandMain]);

	useEffect(() => {
		if (selectedPatientDocument !== viewports?.[0]?.documentId) {
			setSelectedPatientDocument(viewports?.[0]?.documentId);
		}
	}, [viewports]);

	const toggleExpanded = e => {
		e?.preventDefault();
		if (isSelected) {
			setExpanded(val => !val);
			setCollapsed(!expanded);
		}
	};

	const onClickUpload = useCallback(() => {
		refInput?.current?.click();
	}, [refInput]);

	const onUpload = async e => {
		await handleUploadDocument(e);
		// Reset file input value
		refInput.current.value = '';
	};

	return (
		<Accordion
			disableGutters
			expanded={expanded}
			id="doc"
			sx={{
				...STYLES.ACCORDIAN,
				...(galaxyDvPatientChartRefactor && {
					height: '100%',
					'& .MuiCollapse-root': {
						height: 'calc(100% - 64px) !important',
						'.MuiCollapse-wrapper, .MuiCollapse-wrapperInner, .MuiAccordion-region, .MuiAccordionDetails-root':
							{
								height: '100% !important',
							},
					},
				}),
			}}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				id="doc-summary"
				sx={STYLES.ACCORDIAN_SUMMARY}
				onClick={toggleExpanded}
			>
				<Typography
					sx={{
						display: 'inline-flex',
						alignItems: 'center',
					}}
				>
					<PatientDocumentIcon
						style={{
							width: '13px',
							height: 'auto',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							color: 'white',
							marginRight: '8px',
							fontSize: '18px',
							fontFamily: 'Roboto',
							fontWeight: '400',
						}}
					/>
					Patient Document
				</Typography>
				{patientDocuments?.length > 0 && (
					<Typography sx={STYLES.LIST_COUNT}>{patientDocuments.length}</Typography>
				)}
			</AccordionSummary>
			<AccordionDetails sx={STYLES.ACCORDIAN_DETAILS}>
				<input
					ref={refInput}
					accept=".pdf, .doc, .docx, .txt"
					multiple={false}
					name="files"
					style={STYLES.HIDDEN_FILE_INPUT}
					type="file"
					onChange={onUpload}
				/>
				<Filters selectedFilter={selectedDocFilter} setSelectedFilter={setSelectedDocFilter} />
				{patientDocs?.length > 0 && (
					<Box sx={STYLES.CATEGORY_HEAD}>
						<Typography sx={STYLES.CATEGORY_LABEL}>{FILTER_LABEL[selectedDocFilter]}:</Typography>
						{selectedDocFilter !== PATIENT_DOCUMENT_FILTERS.REGISTRATION_FORM && (
							<Tooltip title="Upload Document">
								<IconButton sx={STYLES.UPLOAD_BTN} onClick={onClickUpload}>
									<PublishOutlinedIcon sx={{ fontSize: '20px' }} />
								</IconButton>
							</Tooltip>
						)}
					</Box>
				)}
				<Box
					sx={{
						...STYLES.DOC_LIST_CONT({
							isEmpty: patientDocs?.length === 0,
						}),
						...(!galaxyDvPatientChartRefactor && {
							maxHeight: `${
								!isSelected && isHover
									? 234
									: Math.max(
											(galaxyDvGlobalNotes ? rootHeight : rootHeight + 30) -
												64 -
												66 -
												90 -
												(patientDocs?.length === 0 ? 0 : 24),
											0
									  )
							}px`,
							height: `${
								!isSelected && isHover
									? 234
									: Math.max(
											(galaxyDvGlobalNotes ? rootHeight : rootHeight + 30) -
												64 -
												66 -
												90 -
												(patientDocs?.length === 0 ? 0 : 24),
											0
									  )
							}px`,
						}),
					}}
				>
					{patientDocs?.length ? (
						patientDocs.map(x => (
							<DocumentCard
								key={x.id}
								date={x.createdDate}
								fileName={x.fileName}
								isSelected={selectedPatientDocument === x.id}
								type={x.documentType}
								onClick={() => {
									setSelectedPatientDocument(x.id);
									handleSelectDocument(x);
								}}
								onDragStart={e => e.dataTransfer.setData('text/document', JSON.stringify(x))}
							/>
						))
					) : (
						<NoDocuments
							onClick={
								selectedDocFilter !== PATIENT_DOCUMENT_FILTERS.REGISTRATION_FORM
									? onClickUpload
									: undefined
							}
						/>
					)}
				</Box>
			</AccordionDetails>
		</Accordion>
	);
};

export default PatientDocument;

const STYLES = {
	ACCORDIAN: {
		boxShadow: 'unset',
		background: 'unset',
		'&:before': {
			display: 'none',
		},
	},
	ACCORDIAN_SUMMARY: {
		background: '#363636',
		borderRadius: '10px 10px 0 0',
		position: 'sticky',
		top: '0',
		zIndex: '2',
		minHeight: '64px',
		'& .MuiAccordionSummary-content': {
			margin: '0',
		},
		'&:hover': {
			backgroundColor: '#4f4f4f',
		},
	},
	ACCORDIAN_DETAILS: {
		padding: 0,
		display: 'flex',
		flexDirection: 'column',
		background: '#303030',
		borderRadius: '4px',
	},
	DOC_LIST_CONT: ({ isEmpty }) => ({
		display: 'flex',
		flexDirection: 'column',
		padding: `${isEmpty ? 13 : 1}px 15px 15px`,
		gap: '10px',
		overflowY: 'auto',
	}),
	LIST_COUNT: {
		width: '26px',
		height: '26px',
		borderRadius: '6px',
		background: '#282828',
		fontWeight: '400',
		fontSize: '13px',
		color: '#fff',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		ml: '8px',
	},
	HIDDEN_FILE_INPUT: {
		visibility: 'hidden',
		height: 0,
		overflow: 'hidden',
		width: 0,
		opacity: 0,
		display: 'none',
	},
	CATEGORY_HEAD: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: '40px',
		padding: '0 16px',
	},
	CATEGORY_LABEL: {
		fontSize: '14px',
		lineHeight: '16px',
		fontWeight: '400',
	},
	UPLOAD_BTN: {
		width: '30px',
		height: '30px',
		boxSizing: 'border-box',
	},
};
