import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import PersonIcon from '@mui/icons-material/Person';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const PatientAvatar = ({ name, isLoading, onNavigate, onCollapse, internalPatientId }) => {
	const isLowerHeight = useMediaQuery('(max-height: 800px)');

	return isLoading ? (
		<Skeleton sx={STYLES.AVATAR_LOADER(isLowerHeight)} />
	) : (
		<Box sx={STYLES.AVATAR_BLOCK(isLowerHeight)}>
			<Box sx={STYLES.IMAGE_HEAD}>
				<Tooltip data-testid="name-tooltip" placement="top" title={name}>
					<Box data-testid="name-label" sx={STYLES.NAME_LABEL_CONT} onClick={() => onNavigate()}>
						<Typography sx={STYLES.NAME_LABEL}>{name}</Typography>
						<ArrowOutwardIcon />
					</Box>
				</Tooltip>
				<Box sx={{ display: 'flex' }}>
					{/* <OverflowMenu patientId={internalPatientId} /> */}
					<IconButton data-testid="collapse-btn" sx={STYLES.EXPAND_BUTTON} onClick={() => onCollapse()}>
						<ExpandMoreIcon />
					</IconButton>
				</Box>
			</Box>

			<Box sx={STYLES.IMAGE_BODY}>
				<PersonIcon />
			</Box>
		</Box>
	);
};

export default PatientAvatar;

const STYLES = {
	AVATAR_BLOCK: isLowerHeight => ({
		height: isLowerHeight ? 'unset' : '11.75em',
		borderRadius: '1.25em',
		overflow: 'hidden',
	}),
	AVATAR_LOADER: isLowerHeight => ({
		height: isLowerHeight ? 'unset' : '11.75em',
		borderRadius: '1.25em',
		backgroundColor: 'rgb(42, 42, 42)',
		transformOrigin: '0 0',
		transform: 'scale(1)',
		overflow: 'hidden',
	}),
	IMAGE_HEAD: {
		height: '3em',
		backgroundColor: 'rgb(42, 42, 42)',
		color: 'rgba(255, 255, 255, 0.87)',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '0 0 0 1em',
	},
	NAME_LABEL_CONT: {
		maxWidth: 'calc(100% - 40px)',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		display: 'inline-flex',
		alignItems: 'center',
		flexWrap: 'nowrap',
		cursor: 'pointer',
		'& svg': {
			fontSize: '0.875em',
			ml: '10px',
			pr: '5px',
			color: '#FFFFFF99',
		},
		'&:hover': {
			color: '#fff',
			'& svg': {
				color: '#42A5F5',
			},
		},
	},
	NAME_LABEL: {
		fontFamily: 'ROBOTO',
		textAlign: 'left',
		fontWeight: '500',
		// fontSize: '1em',
		fontSize: '16px',
		lineHeight: 1.5,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	EXPAND_BUTTON: {
		'& svg': {
			fontSize: '24px',
			transform: 'rotate(180deg)',
		},
	},
	IMAGE_BODY: {
		backgroundColor: 'rgb(48, 48, 48)',
		height: '8.75em',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		'& svg': {
			fontSize: '8.125em',
			color: '#4F4F4F',
		},
	},
};
